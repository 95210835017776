/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/respond@0.9.0/main.min.js
 * - /npm/html5shiv@3.7.3/dist/html5shiv.min.js
 * - /npm/selectivizr@1.0.3/selectivizr.min.js
 * - /npm/jquery-waypoints@2.0.5/waypoints.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
